<template lang="pug">
div.view_container(v-bind:class="{small: isSmallScreen}")
  Header(v-on:is-small-screen="setIsSmallScreen")
  v-dialog
  div.view_title Invite people to record a video for {{ recipientName }}
  div.type_email_address(v-if="showInvite")
    div.invited(v-show="haveInvited")
      span You've invited:
      span(v-for="(participant,index) in participants" )
        span(v-if="index!=0") ,
        span.bold.clickable(:title="participant.email" v-on:click="confirmRemove(participant.email)")  {{ participant.name }}
    div.input_container
      input(v-model="name" type="text" placeholder="Type in person's name" ref="name")
    div.input_container
      input(v-model="email" type="text" placeholder="Type in their email and hit enter" v-on:keyup="keyEnterToSubmit" ref="email")
  div.controls
    button.cta(v-on:click="invitePeople") {{ buttonTitle }}
    button.done.google_logo(v-show="showImportAddress && showGoogleSignIn" v-on:click="googleSignIn") Select from address book
    button.done(v-show="!showImportAddress" v-on:click="showInvite=!showInvite") {{ toggleEmailSelectTitle }}
    button.done(v-show="haveInvited" v-on:click="next") Done
  div.loading(v-if="($apollo.loading || loading) && noContacts")
    img(v-bind:src="loadingSrc")
  div.contact_list_container(v-if="!showInvite")
    div.input_container
      input(v-model="searchText" type="text" placeholder="Search your address book"  ref="searchText")
    div.loading(v-if="$apollo.loading || loading")
      img(v-bind:src="loadingSrc")
    div.selected_list(v-if="selectedContacts.length > 0")
      span.label selected: &nbsp;&nbsp;
      span(v-for="(contact,index) in selectedContacts")
        span(v-if="index!=0") ,&nbsp;
        a.clickable(v-on:click="toggleSelectedContact(contact)") {{contact.firstName}} {{contact.lastName}}
  div.list(v-if="!noContacts &&  !showInvite")
    div(v-for="(contact,index) in contactsWithSelectedState")
      div.contact(v-if="!contact.selected" v-bind:class="{active: !contact.invited}" v-on:click="toggleSelectedContact(contact)" :title="contact.email")
        div.name
          span {{truncate(contact.firstName)}}&nbsp;{{ truncate(contact.lastName) }}
          span.invited(v-if="contact.invited") &nbsp;&nbsp;&nbsp;already invited
        div.email {{truncate(contact.email) }}
</template>

<script>
/* global gtag */
import config from '../appConfig'

import { INVITE_PEOPLE_MUTATION, REMOVE_PERSON_MUTATION } from '../graphql/mutations'
import { GET_INVITED_PEOPLE_QUERY, GET_PROJECT_QUERY, GET_CONTACTS_QUERY } from '../graphql/queries'

import Header from '../components/Header'

export default {
  name: 'InvitePeople',
  props: {
    project_id: {
      type: String,
      default: null
    },
  },
  components: {
    Header,
  },
  watch: {
  },
  computed: {
    noContacts () {
      return (!this.contacts || this.contacts.length <= 0) && (this.searchText == "" || this.searchText == null)
    },
    loadingSrc () {
      return process.env.BASE_URL + "loading_dark.gif"
    },
    toggleEmailSelectTitle () {
      if (!this.showInvite) {
        return 'Type in an email address'
      }
      return 'Show address book'
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    recipientName () {
      if (this.currentProject) {
        if (this.currentProject.projectType == "other") return this.currentProject.recipientName
        return this.currentProject.title
      }
      return ""
    },
    currentProject () {
      if (this.$route.params.project_id) {
        return this.project
      }
      return this.$store.state.currentProject
    },
    haveInvited () {
      return this.participants.length > 0
    },
    buttonTitle () {
      if (this.loading) { return "Inviting..." }
      return "Invite"
    },
    showImportAddress () {
      if (this.contacts && this.contacts.length > 0 && (this.searchText == "" || this.searchText == null)) return false
      return true
    },
    contactsWithSelectedState () {
      if (!this.contacts) return null
      return this.contacts.map((contact) =>
        Object.assign(contact, {invited:
        this.participants.findIndex((p) => p.email.toLowerCase() == contact.email.toLowerCase()) >= 0,
        selected: this.selectedContacts.findIndex((p) => p.email == contact.email) >= 0
        })
    )
    },
    selectedContactsForAdding () {
      return this.selectedContacts.map((c)=> {
        return {
          name: (c.firstName != null ? c.firstName : "") + " " + (c.lastName != null ? c.lastName : ""),
          email: c.email
        }
      })
    },
  },
  data () {
    return {
      name: null,
      email: null,
      loading: false,
      participants: [],
      isSmallScreen: config.isSmallScreen(),
      project: null,
      contacts: null,
      showInvite: true,
      searchText: null,
      authCode: null,
      selectedContacts: [],
      showGoogleSignIn: process.env.VUE_APP_SHOW_GOOGLE_SIGNIN,
    }
  },
  apollo: {
    participants () {
      return {
        // gql query
        query: GET_INVITED_PEOPLE_QUERY,
        // Static parameters
        variables () {
          return {
            projectId: this.currentProject.id
          }
        },
        update: data => {
          return data.participants
        },
        skip () {
          return !this.currentProject || !this.isSignedIn
        },
        fetchPolicy: 'network-only',
      }
    },
    project () {
      return {
        // gql query
        query: GET_PROJECT_QUERY,
        // Static parameters
        variables () {
          return {
            id: this.project_id
          }
        },
        update: data => {
          if (!data.project && this.isSignedIn) this.noProjectError()
          return data.project
        },
        error: (error) => {
          console.log(error.message)
        },
        skip () {
          return !this.project_id || !this.isSignedIn
        },
        fetchPolicy: 'network-only',
      }
    },
    contacts () {
      return {
        // gql query
        query: GET_CONTACTS_QUERY,
        variables () {
          return {
            searchText: this.searchText,
            authCode: this.authCode,
          }
        },
        update: data => {
          this.loading = false
          if (data.contacts && data.contacts.length > 0) this.showInvite = false
          return data.contacts
        },
        error: (error) => {
          console.log(error.message)
        },
        fetchPolicy: 'network-only',
      }
    }
  },
  methods: {
    truncate: function(text) {
      if (!text) return

      const email_match = text.match(/(.+)(@.+\.(?:.{3}|.{2}))/)
      if (email_match) {
        if (email_match[1] && email_match[1].length >18) return email_match[1].slice(0,18)+"..."+email_match[2]
        return text
      }

      if (text.length > 18) {
        text = text.slice(0,18) + "..."
      }
      return text
    },
    toggleSelectedContact: function(contact) {
      if (contact.invited) return

      const index = this.selectedContacts.findIndex((c) => c.email == contact.email )
      if (index >= 0) {
        this.selectedContacts.splice(index,1)
      } else {
        this.selectedContacts.push(contact)
      }
    },
    googleSignIn: async function () {
      this.loading = true
      this.authCode = await this.$gAuth.getAuthCode()
    },
    noProjectError: function () {
      this.$toast.error("Unable to find video to invite people to. Click here to close this and we'll take you back to home.", {
        timeout: null,
        onClose: () => this.$router.push({name:"Home"})
      })
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
    next: function() {
      this.$router.push('/add_intro')
    },
    invitePeople: async function() {
      if (!this.currentProject) {
        this.noProjectError()
        return
      }

      const contacts = this.selectedContactsForAdding
      if (this.name && this.email) contacts.push({
        name: this.name,
        email: this.email,
      })

      if (contacts.length <= 0) {
        this.$toast.error('Please enter or select emails to invite.')
        return
      }

      this.loading = true
      this.$apollo.mutate({
        mutation: INVITE_PEOPLE_MUTATION,
        variables: {
          projectId: this.currentProject.id,
          participants: contacts
        },
      }).then((data) => {
        this.loading = false
        // console.log(data)
        this.name = null
        this.email = null
        if (this.$refs.name) this.$refs.name.focus()
        const participants = data.data.addParticipants.participants
        this.selectedContacts = []
        if (participants && participants.length > 0) {
          this.participants = this.participants.concat(participants)
          this.$toast.success("Successfully added: "+participants.map((p) => p.email).join(','))
        } else {
          this.$toast.error("There was a problem with the emails you tried to invite.")
        }
        this.errorsToast(data.data.addParticipants.errors)
      }).catch((error) => {
        this.loading = false
        console.error(error)
        this.errorToast(error)
      })
    },
    confirmRemove: function(participantEmail) {
      this.$modal.show('dialog', {
        title: 'Remove "'+participantEmail+'"?',
        text:'An invite email has already gone out. Removing prevents them from creating a video.',
        buttons: [
          {
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes, remove',
            handler: () => {
              this.removePerson(participantEmail)
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    removePerson: async function(participantEmail) {
      if (!this.currentProject) {
        this.noProjectError()
        return
      }

      this.loading = true
      this.$apollo.mutate({
        mutation: REMOVE_PERSON_MUTATION,
        variables: {
          projectId: this.currentProject.id,
          participantEmail: participantEmail,
        },
      }).then((data) => {
        this.loading = false
        const removedParticipant = data.data.removeParticipant.participant

        if (removedParticipant) {
          this.participants = this.participants.filter((participant) => {return participant.email != removedParticipant.email})
          this.$toast.warning("Removed: "+removedParticipant.email)
        }
        this.errorsToast(data.data.removeParticipant.errors)
      }).catch((error) => {
        this.loading = false
        console.error(error)
        this.errorToast(error)
      })
    },
    keyEnterToSubmit: function (e) {
      if (e.keyCode === 13) {
        this.invitePeople()
      }
    },
  },
  mounted: function () {
    // window.vue = this
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/invite_people'})

    if (!this.isSignedIn) {
      this.$toast.error("Please sign in first. Click here to close this and we'll take you to the sign in page.",{
        timeout: null,
        onClose: () => this.$router.push({
          name:"LoginWithNext",
          params:  {
            next: this.project_id ? "/invite/"+this.project_id : "/"
          }
        })
      })
    } else if (!this.currentProject && !this.project_id) {
      this.noProjectError()
    }
  }
}
</script>

<style scoped>

.view_container {
  display: flex;
  flex-flow: column;

  font-family: gibsonregular;
  font-size: 20px;
  color: #918F99;
}

.input_container {
  margin: 0px 0 18px 0;
}

.invited {
  margin: 0 0 18px 0;
}
#header,
.view_title,
.input_container,
.type_email_address,
.controls,
.contact_list_container,
.loading {
  flex: 0 1 auto;
}

.bold {
  font-weight: bold;
}

.controls {
  margin: 12px 0px;
}

button {
  margin: 0px 20px 20px 0px;
}

button.cta {
  font-size: 20px;
}

button.done {
  font-size: 20px;
  margin: 0px 20px 20px 0px;
  padding: 20px 44px;
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
}

.small button {
  width: 100%;
  margin: 0px 0px 20px 0px;
}

.list {
  border: 1px solid #F5F5F6;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;

  font-family: gibsonregular;
  font-size: 18px;

  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 300px;
}

.selected_list {
  padding: 20px 4%;
  font-family: gibsonsemibold;
}
.label {
  font-size: 18px;
}

.name {
  font-family: gibsonsemibold;
}

.active .name {
  color: #000000;
}

.contact div {
  display: inline-block;
}

.email {
  float: right;
}

a {
  color: #6f63bd;
}

.contact {
  padding: 20px 4%;
  border-bottom: 1px solid #F5F5F6;
  background-color: #f8f8f8;
}

.list .active {
  cursor: pointer;
  background-color: #ffffff;
}

.list .active:hover {
  background-color: #f8f8f8;
}

.invited {
  font-family: gibsonregular;
}

.small .contact .email, .small .contact .name {
  display: block;
  float:none;
  text-align: left;
  margin-bottom: 6px;
}

</style>
